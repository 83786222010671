/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Modifiable settings for an oil temperature formula
 */
export interface OilTemperatureFormulaSettings { 
    /**
     * The coefficients to be inserted into the formula. The number of coefficients that are required depends on the formula's \"type\".  - type 1: 3 coefficients  - type 2: 4 coefficients
     */
    coefficients: Array<number>;
    /**
     * A display name for the formula.
     */
    name: string;
    /**
     * General purpose notes for the system admins that may dealing with the formula ie who the formula is for or why it was created.
     */
    notes: string;
    /**
     * The raw enumeration type used to identify which formula this is. There may be multiple formulas with the same type, but different coefficients. 1. Two variable linear C1 + C2*T1 + C3*T2 2. Three variable linear C1 + C2*T1 + C3*T2 + C4*T3  The temperature variables are as follows:  - T1: 
     */
    type: number;
}
