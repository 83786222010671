/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeasurementExportFilters } from './measurementExportFilters';


export interface MeasurementExportJobsJobsItem { 
    /**
     * The unix timestamp of when the job finished
     */
    endTimestamp?: number;
    filters?: MeasurementExportFilters;
    /**
     * The ID of the job this status is for.
     */
    id: number;
    /**
     * The unix timestamp of when the job was created
     */
    startTimestamp: number;
    /**
     * The URL of the completed export package, if it exists. If the job is not yet complete, this will be null.
     */
    url?: string;
}
