/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * describes a group to be added
 */
export interface GroupToAdd { 
    /**
     * the id of the company to add the group to
     */
    companyID: number;
    /**
     * A maximum 144 character description of the group
     */
    description?: string;
    /**
     * the name of the group to be added
     */
    name: string;
}
