/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface MeasurementExportFilters { 
    /**
     * An array of the device IDs of which to export measurements from.
     */
    deviceIDs?: Array<number>;
    /**
     * A unix timestamp from which to export measurements
     */
    fromTimestamp?: number;
    /**
     * Whether or not to include raw measurements in the export
     */
    includeRaw?: boolean;
    /**
     * The time zone to output the measurements in. If this parameter is not included, the default will be UTC. The expected format is a string from the \"tz\" database eg... \"America/Vancouver\"
     */
    timeZone?: string;
    /**
     * A unix timestamp to export measurements to
     */
    toTimestamp?: number;
}
