/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:02:16Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SubscriptionSettings } from './subscriptionSettings';


export interface Subscription { 
    /**
     * The endpoint to send notifications to. The format depends on the protocol. Email addresses should be in the format address@domain and phone numbers should be in the E.164 format \"+1XXX5550100\"
     */
    endpoint: string;
    id: number;
    /**
     * The type of endpoint to send the notifications to. Currently available options are \"sms\" and \"email\"
     */
    protocol: Subscription.ProtocolEnum;
    settings: SubscriptionSettings;
    /**
     * Indicates whether or not the email or SMS has been verified by the recipient. If the endpoint has not been verified, notifications will not be sent.
     */
    verified: boolean;
}
export namespace Subscription {
    export type ProtocolEnum = 'sms' | 'email';
    export const ProtocolEnum = {
        Sms: 'sms' as ProtocolEnum,
        Email: 'email' as ProtocolEnum
    };
}
