/**
 * dev-ops-aware-help-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:10:50Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentationVersion } from './documentationVersion';


/**
 * A collection of published documentation versions
 */
export interface DocumentationVersionCollection { 
    versions: Array<DocumentationVersion>;
}
