/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * An error reported by a SignalPort
 */
export interface DeviceError { 
    /**
     * the error code
     */
    code: number;
    /**
     * the ID of the device that experienced the error
     */
    deviceID: number;
    /**
     * the name of the file in which the error occurred
     */
    fileName: string;
    /**
     * the line number on which the error occurred
     */
    lineNumber: number;
    /**
     * the UTC Unix timestamp at which the error occurred
     */
    timestampUTC: number;
}
