/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SystemStatusDeviceDatabase } from './systemStatusDeviceDatabase';
import { SystemStatusVersions } from './systemStatusVersions';


/**
 * The status of the system
 */
export interface SystemStatus { 
    deviceDatabase: SystemStatusDeviceDatabase;
    versions: SystemStatusVersions;
}
