/**
 * dev-ops-aware-help-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:10:50Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentationAssetUploadInput } from '../model/documentationAssetUploadInput';
import { DocumentationAssetUploadURL } from '../model/documentationAssetUploadURL';
import { DocumentationPage } from '../model/documentationPage';
import { DocumentationPageCreationInput } from '../model/documentationPageCreationInput';
import { DocumentationPagePathCollection } from '../model/documentationPagePathCollection';
import { DocumentationVersionCollection } from '../model/documentationVersionCollection';
import { DocumentationVersionPublishInput } from '../model/documentationVersionPublishInput';
import { ID } from '../model/iD';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'https://api.dev.ifd.dynamicsynergyiot.com/help';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates a new documentation page at the given path if one does not exist, or creates a new revision if a page does not already exist
     * 
     * @param proxy 
     * @param documentationPageCreationInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrUpdatePage(proxy: string, documentationPageCreationInput: DocumentationPageCreationInput, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public createOrUpdatePage(proxy: string, documentationPageCreationInput: DocumentationPageCreationInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public createOrUpdatePage(proxy: string, documentationPageCreationInput: DocumentationPageCreationInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public createOrUpdatePage(proxy: string, documentationPageCreationInput: DocumentationPageCreationInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (proxy === null || proxy === undefined) {
            throw new Error('Required parameter proxy was null or undefined when calling createOrUpdatePage.');
        }

        if (documentationPageCreationInput === null || documentationPageCreationInput === undefined) {
            throw new Error('Required parameter documentationPageCreationInput was null or undefined when calling createOrUpdatePage.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/pages/${encodeURIComponent(String(proxy))}`,
            documentationPageCreationInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a presigned URL that the user can use to upload an asset
     * 
     * @param documentationAssetUploadInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUploadURL(documentationAssetUploadInput: DocumentationAssetUploadInput, observe?: 'body', reportProgress?: boolean): Observable<DocumentationAssetUploadURL>;
    public createUploadURL(documentationAssetUploadInput: DocumentationAssetUploadInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentationAssetUploadURL>>;
    public createUploadURL(documentationAssetUploadInput: DocumentationAssetUploadInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentationAssetUploadURL>>;
    public createUploadURL(documentationAssetUploadInput: DocumentationAssetUploadInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentationAssetUploadInput === null || documentationAssetUploadInput === undefined) {
            throw new Error('Required parameter documentationAssetUploadInput was null or undefined when calling createUploadURL.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<DocumentationAssetUploadURL>(`${this.basePath}/assets`,
            documentationAssetUploadInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a documentation page at the specified path
     * 
     * @param proxy 
     * @param version The ID of the version to get the page for. If this is omitted, the latest version will be fetched
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPage(proxy: string, version?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentationPage>;
    public getPage(proxy: string, version?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentationPage>>;
    public getPage(proxy: string, version?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentationPage>>;
    public getPage(proxy: string, version?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (proxy === null || proxy === undefined) {
            throw new Error('Required parameter proxy was null or undefined when calling getPage.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (version !== undefined && version !== null) {
            queryParameters = queryParameters.set('version', <any>version);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentationPage>(`${this.basePath}/pages/${encodeURIComponent(String(proxy))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a listing of all the documentation pathes that are available
     * 
     * @param version The ID of the version to get the paths for. If this is omitted, the latest version will be fetched
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPagePaths(version?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentationPagePathCollection>;
    public getPagePaths(version?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentationPagePathCollection>>;
    public getPagePaths(version?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentationPagePathCollection>>;
    public getPagePaths(version?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (version !== undefined && version !== null) {
            queryParameters = queryParameters.set('version', <any>version);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentationPagePathCollection>(`${this.basePath}/pages`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of the documentation versions that have been published
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVersions(observe?: 'body', reportProgress?: boolean): Observable<DocumentationVersionCollection>;
    public getVersions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentationVersionCollection>>;
    public getVersions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentationVersionCollection>>;
    public getVersions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DocumentationVersionCollection>(`${this.basePath}/versions`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Publishes a new version of the documentation.
     * 
     * @param documentationVersionPublishInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public publishVersion(documentationVersionPublishInput: DocumentationVersionPublishInput, observe?: 'body', reportProgress?: boolean): Observable<ID>;
    public publishVersion(documentationVersionPublishInput: DocumentationVersionPublishInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ID>>;
    public publishVersion(documentationVersionPublishInput: DocumentationVersionPublishInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ID>>;
    public publishVersion(documentationVersionPublishInput: DocumentationVersionPublishInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentationVersionPublishInput === null || documentationVersionPublishInput === undefined) {
            throw new Error('Required parameter documentationVersionPublishInput was null or undefined when calling publishVersion.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ID>(`${this.basePath}/versions`,
            documentationVersionPublishInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
