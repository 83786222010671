/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * describes an update for the device settings differs from DeviceSettings because all field are optional
 */
export interface DeviceSettingsUpdate { 
    /**
     * The interval at which to measure the battery. Measuring your battery frequently will decrease the life span of the battery.
     */
    batteryMeasurementIntervalSec?: number;
    /**
     * The measurement interval to use when the device is in an extreme overload state.
     */
    extremeOverloadMeasurementIntervalSec?: number;
    /**
     * The interval at which the device should check in with the cloud in seconds when an extreme overload condition is present in the system.
     */
    extremeOverloadReportingIntervalSec?: number;
    /**
     * Updates the temperature at which the device will be in an extreme overload state in degrees celsius. This value must be higher than the \"overloadThresholdDegC\" value
     */
    extremeOverloadThresholdDegC?: number;
    /**
     * The measurement interval to use when the device is not in an overload state.
     */
    nominalMeasurementIntervalSec?: number;
    /**
     * Updates the measurement interval in seconds.
     */
    overloadMeasurementIntervalSec?: number;
    /**
     * The interval at which the device should check in with the cloud in seconds when an overload condition is present in the system.
     */
    overloadReportingIntervalSec?: number;
    /**
     * updates the temperature after which the device will be in a temperature overload state in a degrees celsius. This value must be lower than the extreme threshold temperature.
     */
    overloadThresholdDegC?: number;
    /**
     * Set to true to enable collection of raw thermistor data with each measurement. If you do this, the measurement interval will be locked at 1 min intervals.
     */
    rawMeasurementsEnabled?: boolean;
    /**
     * The interval at which the device should check in with the cloud in seconds.
     */
    reportingIntervalSec?: number;
}
