/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddDeviceToGroupInput } from '../model/addDeviceToGroupInput';
import { DeviceDescriptionUpdate } from '../model/deviceDescriptionUpdate';
import { DeviceFilters } from '../model/deviceFilters';
import { DeviceFirmwareStats } from '../model/deviceFirmwareStats';
import { DeviceGroupOverviewCollection } from '../model/deviceGroupOverviewCollection';
import { DeviceGroupSettings } from '../model/deviceGroupSettings';
import { DeviceOverviewCollection } from '../model/deviceOverviewCollection';
import { DeviceSettings } from '../model/deviceSettings';
import { DeviceSettingsConditionalUpdate } from '../model/deviceSettingsConditionalUpdate';
import { DeviceSettingsUpdate } from '../model/deviceSettingsUpdate';
import { DevicesStats } from '../model/devicesStats';
import { FirmwareUpdateCollection } from '../model/firmwareUpdateCollection';
import { FirmwareUpdateCollectionV2 } from '../model/firmwareUpdateCollectionV2';
import { FirmwareUpdateRequest } from '../model/firmwareUpdateRequest';
import { GroupToAdd } from '../model/groupToAdd';
import { IDCollection } from '../model/iDCollection';
import { MeasurementExportFilters } from '../model/measurementExportFilters';
import { MeasurementExportJobs } from '../model/measurementExportJobs';
import { MeasurementFrameCollection } from '../model/measurementFrameCollection';
import { RegisterDevicesInput } from '../model/registerDevicesInput';
import { RemoveDevicesFromGroupInput } from '../model/removeDevicesFromGroupInput';
import { TransformerTypeCollection } from '../model/transformerTypeCollection';
import { TransformerTypeToAdd } from '../model/transformerTypeToAdd';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DefaultService {

    protected basePath = 'https://api.dev.ifd.dynamicsynergyiot.com/devices';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Adds a device grouping
     * 
     * @param groupToAdd 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDeviceGroup(groupToAdd: GroupToAdd, observe?: 'body', reportProgress?: boolean): Observable<IDCollection>;
    public addDeviceGroup(groupToAdd: GroupToAdd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IDCollection>>;
    public addDeviceGroup(groupToAdd: GroupToAdd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IDCollection>>;
    public addDeviceGroup(groupToAdd: GroupToAdd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (groupToAdd === null || groupToAdd === undefined) {
            throw new Error('Required parameter groupToAdd was null or undefined when calling addDeviceGroup.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IDCollection>(`${this.basePath}/devicegroups`,
            groupToAdd,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the devices in a group of devices
     * 
     * @param devicegroup device group to update
     * @param addDeviceToGroupInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDevicesToGroup(devicegroup: string, addDeviceToGroupInput: AddDeviceToGroupInput, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addDevicesToGroup(devicegroup: string, addDeviceToGroupInput: AddDeviceToGroupInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addDevicesToGroup(devicegroup: string, addDeviceToGroupInput: AddDeviceToGroupInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addDevicesToGroup(devicegroup: string, addDeviceToGroupInput: AddDeviceToGroupInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (devicegroup === null || devicegroup === undefined) {
            throw new Error('Required parameter devicegroup was null or undefined when calling addDevicesToGroup.');
        }

        if (addDeviceToGroupInput === null || addDeviceToGroupInput === undefined) {
            throw new Error('Required parameter addDeviceToGroupInput was null or undefined when calling addDevicesToGroup.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/devicegroups/${encodeURIComponent(String(devicegroup))}/devices`,
            addDeviceToGroupInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Adds a transformer type
     * 
     * @param transformerTypeToAdd 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTransformerType(transformerTypeToAdd: TransformerTypeToAdd, observe?: 'body', reportProgress?: boolean): Observable<IDCollection>;
    public addTransformerType(transformerTypeToAdd: TransformerTypeToAdd, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IDCollection>>;
    public addTransformerType(transformerTypeToAdd: TransformerTypeToAdd, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IDCollection>>;
    public addTransformerType(transformerTypeToAdd: TransformerTypeToAdd, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (transformerTypeToAdd === null || transformerTypeToAdd === undefined) {
            throw new Error('Required parameter transformerTypeToAdd was null or undefined when calling addTransformerType.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IDCollection>(`${this.basePath}/types/transformer`,
            transformerTypeToAdd,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a measurement frame export job and returns the ID of the newly created job. To find out whether the job is completed or not, use the getMeasurementExportJob method to poll for completion.
     * 
     * @param measurementExportFilters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createMeasurementExportJob(measurementExportFilters: MeasurementExportFilters, observe?: 'body', reportProgress?: boolean): Observable<IDCollection>;
    public createMeasurementExportJob(measurementExportFilters: MeasurementExportFilters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IDCollection>>;
    public createMeasurementExportJob(measurementExportFilters: MeasurementExportFilters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IDCollection>>;
    public createMeasurementExportJob(measurementExportFilters: MeasurementExportFilters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (measurementExportFilters === null || measurementExportFilters === undefined) {
            throw new Error('Required parameter measurementExportFilters was null or undefined when calling createMeasurementExportJob.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IDCollection>(`${this.basePath}/measurements/export/jobs`,
            measurementExportFilters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a device grouping
     * 
     * @param devicegroup group of devices to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDeviceGroup(devicegroup: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteDeviceGroup(devicegroup: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteDeviceGroup(devicegroup: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteDeviceGroup(devicegroup: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (devicegroup === null || devicegroup === undefined) {
            throw new Error('Required parameter devicegroup was null or undefined when calling deleteDeviceGroup.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/devicegroups/${encodeURIComponent(String(devicegroup))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a measurement frame export job. If the job is in the progress it will be cancelled, if it is completed the corresponding data will also be deleted.
     * 
     * @param job the ID of the job to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteMeasurementExportJob(job: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteMeasurementExportJob(job: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteMeasurementExportJob(job: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteMeasurementExportJob(job: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (job === null || job === undefined) {
            throw new Error('Required parameter job was null or undefined when calling deleteMeasurementExportJob.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/measurements/export/jobs/${encodeURIComponent(String(job))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a transformer type
     * 
     * @param transformer transformer to delete
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTransformerType(transformer: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteTransformerType(transformer: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteTransformerType(transformer: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteTransformerType(transformer: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (transformer === null || transformer === undefined) {
            throw new Error('Required parameter transformer was null or undefined when calling deleteTransformerType.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<any>(`${this.basePath}/types/transformer/${encodeURIComponent(String(transformer))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets statistics about the current firmware on list of devices defined by a series of supplied device filters.
     * 
     * @param deviceFilters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceFirmwareStats(deviceFilters: DeviceFilters, observe?: 'body', reportProgress?: boolean): Observable<DeviceFirmwareStats>;
    public getDeviceFirmwareStats(deviceFilters: DeviceFilters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceFirmwareStats>>;
    public getDeviceFirmwareStats(deviceFilters: DeviceFilters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceFirmwareStats>>;
    public getDeviceFirmwareStats(deviceFilters: DeviceFilters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceFilters === null || deviceFilters === undefined) {
            throw new Error('Required parameter deviceFilters was null or undefined when calling getDeviceFirmwareStats.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<DeviceFirmwareStats>(`${this.basePath}/devices/firmware/summary`,
            deviceFilters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the settings for a device group
     * 
     * @param devicegroup Device group to get settings for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceGroupSettings(devicegroup: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceGroupSettings>;
    public getDeviceGroupSettings(devicegroup: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceGroupSettings>>;
    public getDeviceGroupSettings(devicegroup: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceGroupSettings>>;
    public getDeviceGroupSettings(devicegroup: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (devicegroup === null || devicegroup === undefined) {
            throw new Error('Required parameter devicegroup was null or undefined when calling getDeviceGroupSettings.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DeviceGroupSettings>(`${this.basePath}/devicegroups/${encodeURIComponent(String(devicegroup))}/settings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a listing of the available device groups
     * 
     * @param groupIds The IDs of the groups to get. If this field is not present all groups will be queried
     * @param limit The maximum number of devices to get
     * @param sort A sort key for the results
     * @param offset offset to search from
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceGroups(groupIds?: string, limit?: string, sort?: string, offset?: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceGroupOverviewCollection>;
    public getDeviceGroups(groupIds?: string, limit?: string, sort?: string, offset?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceGroupOverviewCollection>>;
    public getDeviceGroups(groupIds?: string, limit?: string, sort?: string, offset?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceGroupOverviewCollection>>;
    public getDeviceGroups(groupIds?: string, limit?: string, sort?: string, offset?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupIds !== undefined && groupIds !== null) {
            queryParameters = queryParameters.set('group_ids', <any>groupIds);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DeviceGroupOverviewCollection>(`${this.basePath}/devicegroups`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets the settings for a single device
     * 
     * @param deviceid ID of the device
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDeviceSettings(deviceid: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceSettings>;
    public getDeviceSettings(deviceid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceSettings>>;
    public getDeviceSettings(deviceid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceSettings>>;
    public getDeviceSettings(deviceid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceid === null || deviceid === undefined) {
            throw new Error('Required parameter deviceid was null or undefined when calling getDeviceSettings.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DeviceSettings>(`${this.basePath}/devices/${encodeURIComponent(String(deviceid))}/settings`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a list of devices that can be seen by the authorized user. The list of devices can also be filtered by a number of parameters as defined the query parameters.
     * 
     * @param groupIds A comma-seperated list of device group IDs to filter that the devices by. Only devices that are associated with one or more of the included groups will be returned
     * @param alarms Applicable alarms
     * @param limit The maximum number of devices to get
     * @param sort The column by which to sort the results.
     * @param reportedAfter Filters for devices which have sent measurements more recently than the provided unix timestamp.
     * @param offset The offset to search for devices from. This is used in concert with limit to provide paging functionality. The offset defines how many devices to skip when returning results.
     * @param transformerTypes A comma-seperated list of transformer type IDs to filter the devices by.  Only devices that are associated with one of the included transformer type IDs will returned.
     * @param serial Does a search for devices which match the given serial.
     * @param deviceIds The device IDs to filter for, if this field is not included all device ids will be included in the search.
     * @param location Filters the device by their GPS coordinates. The format for this filter is a rectangle defined by its north east corner and south west corner &lt;North East Latitude&gt; &lt;North East Longitude&gt;,&lt;South West Latitude&gt; &lt;South West Longitude&gt;
     * @param companyIds Only devices associated with the specified company ID will be included
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevices(groupIds?: string, alarms?: string, limit?: string, sort?: string, reportedAfter?: string, offset?: string, transformerTypes?: string, serial?: string, deviceIds?: string, location?: string, companyIds?: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceOverviewCollection>;
    public getDevices(groupIds?: string, alarms?: string, limit?: string, sort?: string, reportedAfter?: string, offset?: string, transformerTypes?: string, serial?: string, deviceIds?: string, location?: string, companyIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceOverviewCollection>>;
    public getDevices(groupIds?: string, alarms?: string, limit?: string, sort?: string, reportedAfter?: string, offset?: string, transformerTypes?: string, serial?: string, deviceIds?: string, location?: string, companyIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceOverviewCollection>>;
    public getDevices(groupIds?: string, alarms?: string, limit?: string, sort?: string, reportedAfter?: string, offset?: string, transformerTypes?: string, serial?: string, deviceIds?: string, location?: string, companyIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {












        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupIds !== undefined && groupIds !== null) {
            queryParameters = queryParameters.set('group_ids', <any>groupIds);
        }
        if (alarms !== undefined && alarms !== null) {
            queryParameters = queryParameters.set('alarms', <any>alarms);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }
        if (reportedAfter !== undefined && reportedAfter !== null) {
            queryParameters = queryParameters.set('reported_after', <any>reportedAfter);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (transformerTypes !== undefined && transformerTypes !== null) {
            queryParameters = queryParameters.set('transformer_types', <any>transformerTypes);
        }
        if (serial !== undefined && serial !== null) {
            queryParameters = queryParameters.set('serial', <any>serial);
        }
        if (deviceIds !== undefined && deviceIds !== null) {
            queryParameters = queryParameters.set('device_ids', <any>deviceIds);
        }
        if (location !== undefined && location !== null) {
            queryParameters = queryParameters.set('location', <any>location);
        }
        if (companyIds !== undefined && companyIds !== null) {
            queryParameters = queryParameters.set('company_ids', <any>companyIds);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DeviceOverviewCollection>(`${this.basePath}/devices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a set of statistics about the the devices in the system
     * 
     * @param active If present, filters for only active notifications.
     * @param unacknowledged If present, filters only for notifications that have not been acknowledged.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDevicesStats(active?: string, unacknowledged?: string, observe?: 'body', reportProgress?: boolean): Observable<DevicesStats>;
    public getDevicesStats(active?: string, unacknowledged?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DevicesStats>>;
    public getDevicesStats(active?: string, unacknowledged?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DevicesStats>>;
    public getDevicesStats(active?: string, unacknowledged?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (active !== undefined && active !== null) {
            queryParameters = queryParameters.set('active', <any>active);
        }
        if (unacknowledged !== undefined && unacknowledged !== null) {
            queryParameters = queryParameters.set('unacknowledged', <any>unacknowledged);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DevicesStats>(`${this.basePath}/devices/stats`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Polls the status of one or more frame export jobs. When a job is completed, the URL field of it&#39;s status object will be populated, which can be used to download the finished export package. Export packages are a series of CSV files (one for each device involved in the export operation) packaged in a zip file. Export jobs will be available for 7 days after their creation.
     * 
     * @param ids The IDs of specific measurement export jobs to get
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeasurementExportJobs(ids?: string, observe?: 'body', reportProgress?: boolean): Observable<MeasurementExportJobs>;
    public getMeasurementExportJobs(ids?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementExportJobs>>;
    public getMeasurementExportJobs(ids?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementExportJobs>>;
    public getMeasurementExportJobs(ids?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeasurementExportJobs>(`${this.basePath}/measurements/export/jobs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves measurement frames, as well as their associated measurements, from an arbitrary number of devices defined by the \&quot;device_ids\&quot; query parameter. The list can also be filtered between specific timestamps.
     * 
     * @param limit The maximum number of measurement frames to get.
     * @param decimation Decimates the results of the query to provide a coarse overview of the data. The format is \&quot;div:&lt;x&gt;\&quot; where x is the number of seconds that should be used for the length of each decimated segment.
     * @param fromTimestamp The timestamp to search for measurement from, inclusive
     * @param toTimestamp The unix timestamp to search for measurements up to, inclusive.
     * @param paginationToken Pass the pagination token that is returned by this method to this parameter to get the next page of results.
     * @param deviceIds A comma-seperated list of device IDs to filter the results by. Only measurement frames that were created by the included devices will be included in the results
     * @param includeRaw Whether or not to include raw measurements in the results.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMeasurementFrames(limit?: string, decimation?: string, fromTimestamp?: string, toTimestamp?: string, paginationToken?: string, deviceIds?: string, includeRaw?: string, observe?: 'body', reportProgress?: boolean): Observable<MeasurementFrameCollection>;
    public getMeasurementFrames(limit?: string, decimation?: string, fromTimestamp?: string, toTimestamp?: string, paginationToken?: string, deviceIds?: string, includeRaw?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementFrameCollection>>;
    public getMeasurementFrames(limit?: string, decimation?: string, fromTimestamp?: string, toTimestamp?: string, paginationToken?: string, deviceIds?: string, includeRaw?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementFrameCollection>>;
    public getMeasurementFrames(limit?: string, decimation?: string, fromTimestamp?: string, toTimestamp?: string, paginationToken?: string, deviceIds?: string, includeRaw?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (decimation !== undefined && decimation !== null) {
            queryParameters = queryParameters.set('decimation', <any>decimation);
        }
        if (fromTimestamp !== undefined && fromTimestamp !== null) {
            queryParameters = queryParameters.set('from_timestamp', <any>fromTimestamp);
        }
        if (toTimestamp !== undefined && toTimestamp !== null) {
            queryParameters = queryParameters.set('to_timestamp', <any>toTimestamp);
        }
        if (paginationToken !== undefined && paginationToken !== null) {
            queryParameters = queryParameters.set('pagination_token', <any>paginationToken);
        }
        if (deviceIds !== undefined && deviceIds !== null) {
            queryParameters = queryParameters.set('device_ids', <any>deviceIds);
        }
        if (includeRaw !== undefined && includeRaw !== null) {
            queryParameters = queryParameters.set('include_raw', <any>includeRaw);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeasurementFrameCollection>(`${this.basePath}/measurements`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a transformer type
     * 
     * @param offset Offset to start from
     * @param limit Maximum number of entries to get
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTransformerTypes(offset?: string, limit?: string, observe?: 'body', reportProgress?: boolean): Observable<TransformerTypeCollection>;
    public getTransformerTypes(offset?: string, limit?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransformerTypeCollection>>;
    public getTransformerTypes(offset?: string, limit?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransformerTypeCollection>>;
    public getTransformerTypes(offset?: string, limit?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TransformerTypeCollection>(`${this.basePath}/types/transformer`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of available update images
     * 
     * @param ids Filters the images by image IDs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUpdateImages(ids?: string, observe?: 'body', reportProgress?: boolean): Observable<FirmwareUpdateCollection>;
    public getUpdateImages(ids?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FirmwareUpdateCollection>>;
    public getUpdateImages(ids?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FirmwareUpdateCollection>>;
    public getUpdateImages(ids?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FirmwareUpdateCollection>(`${this.basePath}/devices/firmware/update`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Request a list of available update images
     * 
     * @param ids Filters the images by image IDs
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUpdateImagesV2(ids?: string, observe?: 'body', reportProgress?: boolean): Observable<FirmwareUpdateCollectionV2>;
    public getUpdateImagesV2(ids?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FirmwareUpdateCollectionV2>>;
    public getUpdateImagesV2(ids?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FirmwareUpdateCollectionV2>>;
    public getUpdateImagesV2(ids?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids !== undefined && ids !== null) {
            queryParameters = queryParameters.set('ids', <any>ids);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FirmwareUpdateCollectionV2>(`${this.basePath}/devices/firmware/update/v2`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the settings for more than one SignalPort. The SignalPorts that will be updated are defined by a series of filters in the request body.
     * 
     * @param deviceSettingsConditionalUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public massDeviceSettingsUpdate(deviceSettingsConditionalUpdate: DeviceSettingsConditionalUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public massDeviceSettingsUpdate(deviceSettingsConditionalUpdate: DeviceSettingsConditionalUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public massDeviceSettingsUpdate(deviceSettingsConditionalUpdate: DeviceSettingsConditionalUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public massDeviceSettingsUpdate(deviceSettingsConditionalUpdate: DeviceSettingsConditionalUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceSettingsConditionalUpdate === null || deviceSettingsConditionalUpdate === undefined) {
            throw new Error('Required parameter deviceSettingsConditionalUpdate was null or undefined when calling massDeviceSettingsUpdate.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/devices/settings`,
            deviceSettingsConditionalUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reduces the settings of many devices down to a a single set of settings. If all of the devices filtered for have the same settings a value will be returned, if they do not have the same settings the field will be null or undefined.
     * 
     * @param groupIds Filters the result by a provided comma seperated list of device group IDs.
     * @param deviceIds The device IDs to filter for, if this field is not included all device IDs will be included in the search.
     * @param alarms Applicable alarms
     * @param location Location to search in
     * @param transformerTypes types of transformers to use
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reduceDeviceSettings(groupIds?: string, deviceIds?: string, alarms?: string, location?: string, transformerTypes?: string, observe?: 'body', reportProgress?: boolean): Observable<DeviceSettingsUpdate>;
    public reduceDeviceSettings(groupIds?: string, deviceIds?: string, alarms?: string, location?: string, transformerTypes?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeviceSettingsUpdate>>;
    public reduceDeviceSettings(groupIds?: string, deviceIds?: string, alarms?: string, location?: string, transformerTypes?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeviceSettingsUpdate>>;
    public reduceDeviceSettings(groupIds?: string, deviceIds?: string, alarms?: string, location?: string, transformerTypes?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupIds !== undefined && groupIds !== null) {
            queryParameters = queryParameters.set('group_ids', <any>groupIds);
        }
        if (deviceIds !== undefined && deviceIds !== null) {
            queryParameters = queryParameters.set('device_ids', <any>deviceIds);
        }
        if (alarms !== undefined && alarms !== null) {
            queryParameters = queryParameters.set('alarms', <any>alarms);
        }
        if (location !== undefined && location !== null) {
            queryParameters = queryParameters.set('location', <any>location);
        }
        if (transformerTypes !== undefined && transformerTypes !== null) {
            queryParameters = queryParameters.set('transformer_types', <any>transformerTypes);
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<DeviceSettingsUpdate>(`${this.basePath}/devices/settings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Registers a group of devices
     * 
     * @param registerDevicesInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerDevices(registerDevicesInput: RegisterDevicesInput, observe?: 'body', reportProgress?: boolean): Observable<IDCollection>;
    public registerDevices(registerDevicesInput: RegisterDevicesInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IDCollection>>;
    public registerDevices(registerDevicesInput: RegisterDevicesInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IDCollection>>;
    public registerDevices(registerDevicesInput: RegisterDevicesInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (registerDevicesInput === null || registerDevicesInput === undefined) {
            throw new Error('Required parameter registerDevicesInput was null or undefined when calling registerDevices.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<IDCollection>(`${this.basePath}/devices`,
            registerDevicesInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes devices from a device group
     * 
     * @param devicegroup The ID the device group to remove a device from
     * @param removeDevicesFromGroupInput 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeDevicesFromGroup(devicegroup: string, removeDevicesFromGroupInput: RemoveDevicesFromGroupInput, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public removeDevicesFromGroup(devicegroup: string, removeDevicesFromGroupInput: RemoveDevicesFromGroupInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public removeDevicesFromGroup(devicegroup: string, removeDevicesFromGroupInput: RemoveDevicesFromGroupInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public removeDevicesFromGroup(devicegroup: string, removeDevicesFromGroupInput: RemoveDevicesFromGroupInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (devicegroup === null || devicegroup === undefined) {
            throw new Error('Required parameter devicegroup was null or undefined when calling removeDevicesFromGroup.');
        }

        if (removeDevicesFromGroupInput === null || removeDevicesFromGroupInput === undefined) {
            throw new Error('Required parameter removeDevicesFromGroupInput was null or undefined when calling removeDevicesFromGroup.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/devicegroups/${encodeURIComponent(String(devicegroup))}/devices/remove`,
            removeDevicesFromGroupInput,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets the update image for all the devices matching the specified filters
     * 
     * @param firmwareUpdateRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setDeviceUpdateImages(firmwareUpdateRequest: FirmwareUpdateRequest, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public setDeviceUpdateImages(firmwareUpdateRequest: FirmwareUpdateRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public setDeviceUpdateImages(firmwareUpdateRequest: FirmwareUpdateRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public setDeviceUpdateImages(firmwareUpdateRequest: FirmwareUpdateRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (firmwareUpdateRequest === null || firmwareUpdateRequest === undefined) {
            throw new Error('Required parameter firmwareUpdateRequest was null or undefined when calling setDeviceUpdateImages.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/devices/firmware/update`,
            firmwareUpdateRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Unregister a list of devices from the API
     * 
     * @param deviceFilters 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unregisterDevices(deviceFilters: DeviceFilters, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public unregisterDevices(deviceFilters: DeviceFilters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public unregisterDevices(deviceFilters: DeviceFilters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public unregisterDevices(deviceFilters: DeviceFilters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceFilters === null || deviceFilters === undefined) {
            throw new Error('Required parameter deviceFilters was null or undefined when calling unregisterDevices.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/devices/unregister`,
            deviceFilters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the description of a device
     * 
     * @param deviceid ID of the device to update
     * @param deviceDescriptionUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeviceDescription(deviceid: string, deviceDescriptionUpdate: DeviceDescriptionUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateDeviceDescription(deviceid: string, deviceDescriptionUpdate: DeviceDescriptionUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateDeviceDescription(deviceid: string, deviceDescriptionUpdate: DeviceDescriptionUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateDeviceDescription(deviceid: string, deviceDescriptionUpdate: DeviceDescriptionUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceid === null || deviceid === undefined) {
            throw new Error('Required parameter deviceid was null or undefined when calling updateDeviceDescription.');
        }

        if (deviceDescriptionUpdate === null || deviceDescriptionUpdate === undefined) {
            throw new Error('Required parameter deviceDescriptionUpdate was null or undefined when calling updateDeviceDescription.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/devices/${encodeURIComponent(String(deviceid))}/description`,
            deviceDescriptionUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the settings for a device group
     * 
     * @param devicegroup Device group to get settings for
     * @param deviceGroupSettings 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeviceGroupSettings(devicegroup: string, deviceGroupSettings: DeviceGroupSettings, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateDeviceGroupSettings(devicegroup: string, deviceGroupSettings: DeviceGroupSettings, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateDeviceGroupSettings(devicegroup: string, deviceGroupSettings: DeviceGroupSettings, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateDeviceGroupSettings(devicegroup: string, deviceGroupSettings: DeviceGroupSettings, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (devicegroup === null || devicegroup === undefined) {
            throw new Error('Required parameter devicegroup was null or undefined when calling updateDeviceGroupSettings.');
        }

        if (deviceGroupSettings === null || deviceGroupSettings === undefined) {
            throw new Error('Required parameter deviceGroupSettings was null or undefined when calling updateDeviceGroupSettings.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/devicegroups/${encodeURIComponent(String(devicegroup))}/settings`,
            deviceGroupSettings,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates the settings of a single device
     * 
     * @param deviceid ID of the device
     * @param deviceSettingsUpdate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateDeviceSettings(deviceid: string, deviceSettingsUpdate: DeviceSettingsUpdate, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public updateDeviceSettings(deviceid: string, deviceSettingsUpdate: DeviceSettingsUpdate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public updateDeviceSettings(deviceid: string, deviceSettingsUpdate: DeviceSettingsUpdate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public updateDeviceSettings(deviceid: string, deviceSettingsUpdate: DeviceSettingsUpdate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (deviceid === null || deviceid === undefined) {
            throw new Error('Required parameter deviceid was null or undefined when calling updateDeviceSettings.');
        }

        if (deviceSettingsUpdate === null || deviceSettingsUpdate === undefined) {
            throw new Error('Required parameter deviceSettingsUpdate was null or undefined when calling updateDeviceSettings.');
        }

        let headers = this.defaultHeaders;

        // authentication (IFDApiAuthorizer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/devices/${encodeURIComponent(String(deviceid))}/settings`,
            deviceSettingsUpdate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
