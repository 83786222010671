/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareVersion } from './firmwareVersion';


/**
 * Information about a DSB peripheral
 */
export interface PeripheralInfo { 
    firmwareVersion: FirmwareVersion;
    /**
     * The ID of the peripheral
     */
    id: number;
    /**
     * The revision of the peripheral's PCB
     */
    pcbRevision: number;
    /**
     * The serial number of the peripheral
     */
    serial?: string;
    /**
     * The type of the peripheral
     */
    type: string;
    /**
     * The base64 unique ID of the peripheral
     */
    uniqueID: string;
}
