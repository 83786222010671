/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeviceOverview } from './deviceOverview';
import { PeripheralInfo } from './peripheralInfo';


/**
 * A collection of device overview objects
 */
export interface DeviceOverviewCollection { 
    /**
     * Information about the peripherals attached to the devices.
     */
    peripherals?: { [key: string]: PeripheralInfo; };
    /**
     * The device overviews that were found
     */
    results: Array<DeviceOverview>;
    totalResultCount: number;
}
