/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareImage } from './firmwareImage';
import { FirmwareUpdateSettings } from './firmwareUpdateSettings';


/**
 * Admin information about a firmware update file
 */
export interface AdminFirmwareUpdate { 
    /**
     * The pre-signed URL for downloading the associated firmware image on the administrative side.
     */
    changeLogUrl?: string;
    /**
     * The firmware image's ID
     */
    id: number;
    image: FirmwareImage;
    settings: FirmwareUpdateSettings;
    /**
     * The timestamp of when the firmware image was added.
     */
    uploadTimestamp: number;
}
