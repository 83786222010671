/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdminFirmwareUpdate } from './adminFirmwareUpdate';


/**
 * A collection of firmware update information entries
 */
export interface AdminFirmwareUpdateCollection { 
    /**
     * An array of application updates
     */
    applicationUpdates: Array<AdminFirmwareUpdate>;
}
