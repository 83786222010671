/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:02:16Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A representation of a location on a map
 */
export interface Location { 
    /**
     * The latitude of the location in decimal degrees.
     */
    latitude: number;
    /**
     * The longitude of the location in decimal degrees.
     */
    longitude: number;
}
