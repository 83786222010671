/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DeviceFilters } from './deviceFilters';
import { FirmwareUpdateChange } from './firmwareUpdateChange';


/**
 * Request for updating SignalPort firmware
 */
export interface FirmwareUpdateRequest { 
    app?: FirmwareUpdateChange;
    filters: DeviceFilters;
    modem?: FirmwareUpdateChange;
}
