/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareVersion } from './firmwareVersion';


/**
 * describes a piece of firmware
 */
export interface FirmwareDescription { 
    /**
     * The identifier of this application
     */
    appID: number;
    /**
     * The type of image
     */
    imageType?: string;
    /**
     * The identifier for the product
     */
    productID: number;
    /**
     * Unique signature generated by the image contents
     */
    signature: number;
    version: FirmwareVersion;
}
