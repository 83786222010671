/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:02:16Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Notification } from './notification';
import { UserNotificationCounts } from './userNotificationCounts';


/**
 * collection of notifications
 */
export interface NotificationCollection { 
    counts?: UserNotificationCounts;
    results: Array<Notification>;
}
