/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:02:16Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A description of a single user to add to a company
 */
export interface CompanyUserInvite { 
    /**
     * The emails of the user's to invite to the company
     */
    email: string;
    /**
     * The role the user will have in the company
     */
    role: string;
}
