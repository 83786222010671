/**
 * dev-ops-aware-logs-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:05:25Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LogEntry } from './logEntry';


/**
 * collection of log entries
 */
export interface LogCollection { 
    logs: Array<LogEntry>;
    /**
     * The total number of log entries unlimited by paging parameters
     */
    totalResultCount: number;
}
