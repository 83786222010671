/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Settings for a Firmware Update
 */
export interface FirmwareUpdateSettings { 
    /**
     * Indicates whether the update image is currently active. An deactivated image will be invisible to users.
     */
    active: boolean;
    /**
     * White list for which companies are allowed to see this image when it is in a \"staging\" state.
     */
    allowedStagingCompanies: Array<number>;
    /**
     * White list for which company groups are allowed to see this image when it is in a \"staging\" state.
     */
    allowedStagingCompanyGroups: Array<number>;
    /**
     * The reason the device was deactivated. This is only present if \"active\" is being changed to \"deactivated\"
     */
    deactivationReason: string;
    /**
     * Whether or not the firmware image is currently in \"staging\". When an update image is in staging, it will only be visible to people who are part of the selected companies.
     */
    staging: boolean;
}
