/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:02:16Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { APIKeySettings } from './aPIKeySettings';


export interface APIKey { 
    /**
     * The API key
     */
    apiKey?: string;
    /**
     * The company ID the API key belongs to
     */
    companyID?: number;
    /**
     * Database identifier of the API key
     */
    id?: number;
    settings?: APIKeySettings;
}
