/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Location } from './location';
import { UserCompanyAssociation } from './userCompanyAssociation';


/**
 * A user overview
 */
export interface UserOverview { 
    /**
     * If the user has accepted the use terms
     */
    acceptedTerms?: boolean;
    /**
     * map of the associations between a user and the companies they are associated with. The key is a stringified version of the company ID number
     */
    associations: { [key: string]: UserCompanyAssociation; };
    /**
     * The user's email address
     */
    email: string;
    /**
     * User's first name
     */
    firstName?: string;
    /**
     * The user's Sub from cognito
     */
    guid: string;
    /**
     * User's home location
     */
    homeLocation?: Location;
    /**
     * The level of zoom at the user's home location
     */
    homeZoom?: number;
    /**
     * User's database ID
     */
    id: number;
    /**
     * The user's SystemAdmin status
     */
    isSystemAdmin?: boolean;
    /**
     * User's last name
     */
    lastName?: string;
    /**
     * User's name
     */
    name: string;
    /**
     * The user's preferred time zone. The value will be in the \"tz database\" format eg. \"America/Vancouver\"
     */
    timeZone?: string;
}
