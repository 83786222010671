/**
 * dev-ops-aware-devices-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:04:00Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * A measurement of a battery's state
 */
export interface BatteryState { 
    /**
     * The Unix timestamp of when the measurement occurred.
     */
    timestamp: number;
    /**
     * The voltage measured at the battery's terminals.
     */
    voltage: number;
}
