/**
 * dev-ops-aware-users-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:02:16Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Count of the total number of notifications associated with a user
 */
export interface UserNotificationCounts { 
    /**
     * The total number of notifications that have been acknowledged.
     */
    acknowledged: number;
    /**
     * The total number of active notifications in the system.
     */
    active: number;
    /**
     * The total number of notifications associated with the user.
     */
    total: number;
    /**
     * The total number of notifications that the user has not seen so far.
     */
    unseen?: number;
}
