/**
 * dev-ops-aware-admin-api-green
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2024-10-09T17:07:02Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FirmwareDescription } from './firmwareDescription';


/**
 * Info about the image contained in a firmware update
 */
export interface FirmwareImage { 
    /**
     * The base address of the firmware image
     */
    baseAddress: number;
    firmwareDescription: FirmwareDescription;
    /**
     * The format of the firmware image
     */
    format: number;
    /**
     * The length of the firmware image in bytes
     */
    lengthInBytes: number;
}
